.example1 {
    height: 50px;  
    overflow: hidden;
    position: relative;
   }
   .example1 h3{
  
    color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    /* Starting position */
    -moz-transform:translateX(100%);
    -webkit-transform:translateX(100%);    
    transform:translateX(100%);
    /* Apply animation to this element */  
    -moz-animation: example1 15s linear infinite;
    -webkit-animation: example1 15s linear infinite;
    animation: example1 15s linear infinite;
   }
   /* Move it (define the animation) */
   @-moz-keyframes example1 {
    0%   { -moz-transform: translateX(100%); }
    100% { -moz-transform: translateX(-100%); }
   }
   @-webkit-keyframes example1 {
    0%   { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); }
   }
   @keyframes example1 {
    0%   { 
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);       
    }
    100% { 
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%); 
    }
   }

   hr {
    border: 0;
    clear:both;
    display:block;
    width: 100%;         
    background-color:black;
    height: 1px;
  }
  .clickable{
      cursor: pointer;
  }

  input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: lightgray;
    width: 100%;
  }
  input:focus {
    border-color: red
  }

  .banner{
    background-color:#dadada;
    color: limegreen;
    font-weight: bold;
    padding:0px 10px;
  }

  body{
    background-color:  #BEBEBE;
  }
  .lightGrey{
    color:grey
  }
  td    {
    padding: 10px;
 font-size: 15pt;
font-weight: 500;}